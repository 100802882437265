import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import GoToTop from '../components/ui/GoToTop'


const HumanRobotInteraction = (props) => (
    <Layout>
        <Helmet>
            <title>HRI - Jonathan Camargo</title>
            <meta name="description" content="Human robot interaction" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Human Robot Interaction</h1>
                    </header>                 
                    <p> The future of robotics is with systems that can naturally work with humans. Certainly, wearable robotics and human augmentation are fields where this seamless connection is fundamental, given that the robot is attached to the user. From making and fitting a prosthesis to creating adaptable controllers, my work involves considering the user's needs,
and the way they interact with the devices. 
                    </p>
                    <p> Besides that work, I'm interested in exploring emotional robotics concepts to create better interactive experiences with robots. I have focused on creating robotic assistants using emotion-based approaches that could help treat body focus repetitive behaviors. As part of a beta-developing experience with Anki for Dr. Sonia Chernova's HRI course, I implemented behaviors based on pattern recognition for the robotic toy Cozmo. My work, presented at the <a href="https://ieeexplore.ieee.org/document/8287695">Computer and Robot Vision Conference (CRV)</a> uses a two-axis temperament scale that describes the emotion and triggers reactions according to the robot's state as an x-y coordinate system.
                    We can even apply these ideas to agents that do not have physical embodiment, for example, in wearable UX systems as we did for <a href="http://lyricliu.com/hci_hw4/">smartwatches</a>.</p>
                
                    <div class="videoWrapper" style={{AspectRatio: "3 / 4"}}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/v4rU97BpK7Q" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>                                          
                </div>
            </section>


            <section id="two">
                    <div className="inner">                   
                        <p> The UX in robotics is an exciting research area and one that I'm very passionate about. For Spring 2021, I was recruited as a Ph.D. intern at <a href="https://tech.fb.com/ar-vr/">Facebook Reality Labs</a>. During six months, I will be using the expertise I have gained in machine learning and biomechanics to evaluate and improve hand tracking algorithms for applications in AR/VR systems. This experience will open more research possibilities, combining the understanding of human motion with robotic manipulation, exploring the user perception, developing solutions in real, virtual, or mixed settings.<br></br> 
                            <h4>Right now, it feels amazing to be someone working in the lab!</h4>    
                        </p>                                                
                        <div class="videoWrapper" style={{AspectRatio: "3 / 4"}}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/82tdTAA4TtQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>                              
                    </div>
            </section> 

            <GoToTop></GoToTop>


        </div>
            


    </Layout>
)

export default HumanRobotInteraction